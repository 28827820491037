import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddNewButtonComponent } from './add-new-button/add-new-button.component';
import { ImportarButtonComponent } from './import-button/import-button.component';
import { TutorialButtonComponent } from './tutorial-button/tutorial-button.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TutorialButtonComponent,
        ImportarButtonComponent,
        AddNewButtonComponent,
    ],
    exports: [
        TutorialButtonComponent,
        ImportarButtonComponent,
        AddNewButtonComponent,
    ],
})
export class UIComponentsButtonsGestioModule {}
