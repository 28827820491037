export const environment = {
    production: false,
    local: true,

    otkerpApiAuth: '/auth-api',

    otkerpApiAuthWeb: '/auth-web-api',

    otkerpApiGeneral: '/general-api',

    otkerpApiPurchase: '/purchase-api',

    otkerpApiStock: '/stock-api',

    otkerpApiFinancial: '/financial-api',

    otkerpApiSales: '/sales-api',

    otkerpApiReports: '/reports-api',

    otkerpApiSystemApplication: '/system-application-api',
};
